
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={process.env.PUBLIC_URL + '/logo_cryt.png'} className="App-logo" alt="logo" />
        <h1>CRYT</h1>
      </header>
    </div>
  );
}

export default App;
